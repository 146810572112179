$(function () {

  // (function () {
  //   // トップページのフェードイン
  //       let fadeSpeed = 500;

  //       $('.l-bigLogo').animate({
  //         'opacity': 1
  //       }, fadeSpeed,'linear');

  //       $('.p-headerTop').animate({
  //         'opacity': 1
  //       }, fadeSpeed + 600);
  //   })();

  (function () {

    let $slideWord = $('.p-feature__miniTitle'),
      showHeight = 100;

    $(window).on('load scroll resize', function () {

      $slideWord.each(function () {
        let setThis = $(this),
          wordTop = setThis.offset().top;
        if ($(window).scrollTop() > (wordTop + showHeight) - $(window).height()){
          setThis.addClass('slideInOut')
        // } else {
        //   setThis.removeClass('slideInOut')
        }
      })
    })
  })();

  (function () {

    let $fadeWord = $('.p-promiseNum__numTitle'),
      showHeight = 50;

      $fadeWord.css({ opacity: '0' })

    $(window).on('load scroll resize', function () {
      $fadeWord.each(function () {
        let setThis = $(this),
          wordTop = setThis.offset().top;

        if ($(window).scrollTop() > (wordTop - showHeight) - $(window).height()) {
          setThis.stop().animate({ opacity: '1'}, 100, 'linear');
        // } else {
        //   setThis.stop().animate({ opacity: '0'}, 100);
        }
      })
    })
  })();

  (function () {

    let $fadeWord = $('.p-promiseMain__item'),
      showHeight = -200;

      $fadeWord.css({ opacity: '0' })

    $(window).on('load scroll resize', function () {
      $fadeWord.each(function () {
        let setThis = $(this),
          wordTop = setThis.offset().top;

        if ($(window).scrollTop() > (wordTop - showHeight) - $(window).height()) {
          setThis.stop().animate({ opacity: '1'}, 80, 'linear');
        // } else {
        //   setThis.stop().animate({ opacity: '0'}, 80);
        }
      })
    })
  })();
});
